// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-back-office-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\services\\back-office.js" /* webpackChunkName: "component---src-pages-services-back-office-js" */),
  "component---src-pages-services-call-center-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\services\\call-center.js" /* webpackChunkName: "component---src-pages-services-call-center-js" */),
  "component---src-pages-services-it-solutions-js": () => import("A:\\dev\\gatsby\\smartsolution-llc\\src\\pages\\services\\it-solutions.js" /* webpackChunkName: "component---src-pages-services-it-solutions-js" */)
}

